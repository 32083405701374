import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import { toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import DeleteModal from "./DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import backarrow from "../../assets/images/back-arrow.svg";
import { useQueryClient } from "react-query";

// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";
// crop image end
import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
import { getMandatoryFields, getExtraFields } from "../../mandatoryfileds";
import { useContext } from "react";
import { GlobalContext } from "../../globalContext";
import { Button } from "react-bootstrap";
import OGtags from "./OGtags";
// let bwebsiteId="";
// if (window.location.origin=="http://localhost:3001")
//     bwebsiteId = "TOC";
// else if (window.location.origin=="http://localhost:3000")
//     bwebsiteId = "AHR";

const CreatePressRelease = () => {
  const { bwebsiteId } = useContext(GlobalContext);
  const moduleName = "pressrelease";
  // Get the mandatory fields for the specific websiteId and moduleName
  const mandatoryFields = getMandatoryFields(bwebsiteId, moduleName);
  const extraFields = getExtraFields(bwebsiteId, moduleName);

  const pressReleaseSchema = Yup.object({
    title: Yup.string().trim().required("Please enter title "),
    pressReleaseDate: Yup.string()
      .trim()
      .test(
        "is-pressReleaseDate-required",
        "Please select press release date",
        function (value) {
          return !mandatoryFields.includes("pressReleaseDate") || !!value; // If discountText is required, ensure it's not empty
        }
      ),
    // pressReleaseTime: Yup.string(),//condition
    pressReleaseTime: Yup.string()
      .trim()
      .test(
        "is-pressReleaseTime-required",
        "Please select press release time",
        function (value) {
          return !mandatoryFields.includes("pressReleaseTime") || !!value; // If discountText is required, ensure it's not empty
        }
      ),
    description: Yup.string().trim().required("Please enter description"),
    slugName: Yup.string()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        "Only alphanumeric characters, hyphens, and underscores are allowed"
      )
      .required("Please enter slug"),
    metaTitle: Yup.string().trim(),
    keyWords: Yup.string().trim(),
    metaDescription: Yup.string().trim(),
    // resortId: Yup.number().required("Please Select Resort"),
  });
  const navigate = useNavigate();
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [description, setdescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const client = useQueryClient();

  const [ogImagePlaceholer, setOgImage] = useState(null);
  const [selectedImageValue, setSelectedImageValue] = useState(null);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [err, setErr] = useState(false);
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = () => {
    setSelectedImageValue("PRESSIMAGE");
    setCropMediaModal(true);
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "press_release_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("press_release_aspect_ratio")
  );
  const getOgAspectRatio = useQuery(
    ["AspectRatio", "og_image_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("og_image_aspect_ratio")
  );
  // crop code end

  const handleShowModal = (rowData, title) => {
    setSelectedRowData(rowData);
    setSelectedRowNameData(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const { id } = useParams();

  const getsinglePressRelease = useQuery(
    ["SinglePressRelease", id], // Using an array as the query key with offerId
    () => querydata.getSinglePressRelease(id)
  );

  useEffect(() => {
    const ogImage = getsinglePressRelease?.data?.data?.og_image;
    setOgImage(ogImage);
  }, [getsinglePressRelease?.data]);

  const initialValues = {
    title:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.title
        ? getsinglePressRelease.data.data.title
        : "",
    slugName:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.slug
        ? getsinglePressRelease.data.data.slug
        : "",
    metaTitle:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.meta_title
        ? getsinglePressRelease.data.data.meta_title
        : "",
    keyWords:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.keywords
        ? getsinglePressRelease.data.data.keywords
        : "",
    metaDescription:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.meta_description
        ? getsinglePressRelease.data.data.meta_description
        : "",
    pressReleaseDate:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.date
        ? getsinglePressRelease.data.data?.modifieddate?.split("T")[0] ?? ""
        : "",
    pressReleaseTime:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data?.time
        ? getsinglePressRelease.data.data?.time
        : "",
    description:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.description
        ? getsinglePressRelease.data.data.description
        : "",
    tags:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.tags
        ? getsinglePressRelease.data.data.tags
        : "",
    imageAltTag:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.image_alt_tag
        ? getsinglePressRelease.data.data.image_alt_tag
        : "",
    is_published:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.is_published
        ? getsinglePressRelease.data.data.is_published
        : "",
    resortId:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.brand_resort_ids
        ? getsinglePressRelease.data.data.brand_resort_ids
        : "",
    publish_date:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data?.published_date !== null
        ? getsinglePressRelease.data.data?.published_date?.split("T")[0]
        : "",

    publish_time:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.published_date !== null
        ? getsinglePressRelease.data.data?.published_date
            ?.split("T")[1]
            .split(".")[0]
            .slice(0, 5)
        : "",

    // og tags
    ogTitle:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.og_title
        ? getsinglePressRelease.data.data.og_title
        : "",
    ogDescription:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.og_description
        ? getsinglePressRelease.data.data.og_description
        : "",
    ogType:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.og_type
        ? getsinglePressRelease.data.data.og_type
        : "",
    ogUrl:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.og_url
        ? getsinglePressRelease.data.data.og_url
        : "",
    // ogImage: ogImagePlaceholer,
    ogSiteName:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.og_site_name
        ? getsinglePressRelease.data.data.og_site_name
        : "",
  };
  useEffect(() => {
    setValues({ ...initialValues });
    const originalUrl =
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.image_path;
    const modifiedUrl =
      originalUrl &&
      originalUrl.replace("http://localhost:8000", "http://172.16.1.123:8000");
    setmainCroppedImage(modifiedUrl);
    setImage(modifiedUrl);
    const descriptionValue =
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.description;

    setFieldValue("description", descriptionValue);
    // setFieldValue("description", descriptionValue);
  }, [getsinglePressRelease.data]);

  useEffect(() => {
    if (maincroppedFile) {
      setErr(false);
    }
    if (maincroppedImage) {
      setErr(false);
    }
  }, [maincroppedFile]);

  const titleRef = useRef(null);
  const pressReleaseDateRef = useRef(null);
  const pressReleaseTimeRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const slugRef = useRef(null);
  const handleTitleChange = (event) => {
    const value = event.target.value;

    setFieldValue("title", value);
    // setFieldValue('slugName', value.toLowerCase().replace(/\s+/g, '-'));
  };

  const getAllResorts = useQuery("Resorts", querydata.getAllResorts);
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };
  const sanitizeValue = (value) => {
    const div = document.createElement("div");
    div.innerHTML = value;
    if (div.innerText.trim() === "") {
      setErrors({ description: "Please enter a description" });
      setTouched({ description: true });
      return "";
    }

    return value;
  };
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setAction] = useState();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    setErrors,
    touched,
    setValues,
    setFieldValue,
    setFieldError,

    setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: pressReleaseSchema,
    onSubmit: (values, action) => {
      const sanitizedDescription = sanitizeValue(values.description);
      const formData = new FormData();
      formData.append("title", values.title);
      if (values.pressReleaseDate != "")
        formData.append("date", values.pressReleaseDate);
      if (values.pressReleaseTime != "")
        formData.append("time", values.pressReleaseTime);
      formData.append("description", sanitizedDescription);
      formData.append("tags", values.tags);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("is_published", values.is_published);
      formData.append("brand_resort_id", values.resortId);
      formData.append("publish_date", values.publish_date);
      formData.append("publish_time", values.publish_time);

      formData.append("imageAltTag", values.imageAltTag);
      formData.append("editId", id);

      // og tags
      formData.append("ogTitle", values.ogTitle);
      formData.append("ogDescription", values.ogDescription);
      formData.append("ogType", values.ogType);
      formData.append("ogUrl", values.ogUrl);
      formData.append("ogSiteName", values.ogSiteName);

      if (typeof ogImagePlaceholer === "string") {
        const fileName = ogImagePlaceholer.split("/").pop(); //getting the file name i.e pop remove the last emement from the array
        formData.append("ogImage", fileName);
      } else {
        formData.append("ogImage", ogImagePlaceholer);
      }

      if (!err) {
        if (maincroppedFile) {
          formData.append("pressImage", maincroppedFile);
        } else {
          formData.append("pressImage", maincroppedImage);
        }
      }
      // If maincroppedFile is null, set an error message for the pressImage field
      if (err) {
        if (imageRef.current) {
          imageRef.current.focus();
        }
        toast.dismiss();
        toast.error("Please upload an image");
        return;
      }
      if (!err) {
        if (actions === "PREVIEW") {
          preview.mutate(formData);
        }
        if (actions === "SAVE") {
          addMutation.mutate({ id, formData });
        }
      }

      //action.resetForm();
    },
    // validateOnBlur: true,
    // validateOnChange: true,
  });

  // useEffect(() => {
  //   if (Object.values(errors).some((error) => error !== undefined)) {
  //     const firstEmptyField = Object.entries(errors).find(
  //       ([, error]) => error !== undefined && !touched[error.path]
  //     );

  //     if (firstEmptyField) {
  //       const [name] = firstEmptyField;
  //       switch (name) {
  //         case "title":
  //           titleRef.current.focus();
  //           break;
  //         case "pressReleaseDate":
  //           pressReleaseDateRef.current.focus();
  //           break;
  //         case "pressReleaseTime":
  //           pressReleaseTimeRef.current.focus();
  //           break;
  //         case "description":
  //           descriptionRef.current.focus();
  //           break;
  //         case "slugName":
  //           slugRef.current.focus();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }
  // }, [errors, touched]);

  const handelFocus = () => {
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;
        switch (name) {
          case "title":
            titleRef.current.focus();
            break;
          case "pressReleaseDate":
            pressReleaseDateRef.current.focus();
            break;
          case "pressReleaseTime":
            pressReleaseTimeRef.current.focus();
            break;
          case "description":
            descriptionRef.current.focus();
            break;
          case "slugName":
            slugRef.current.focus();
            break;
          default:
            break;
        }
      }
    }

    handleSubmit();
  };

  const preview = useMutation(querydata.previewPressRelease, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      const websiteUrl = `${data.previewURL}?ispreview=true&uid=${data.encryptedUserId}`;
      window.open(websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });
  //form post
  const addMutation = useMutation(querydata.updatePressRelease, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-press-releases");
      client.invalidateQueries("SinglePressRelease");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleRemoveImage = () => {
    // Handle removing the image
    setErr(true);
    setImage(null);
    setmainCroppedImage(null);
    setmainCroppedFile(null);
  };

  ///editor start
  const fullEditorRef = useRef(null);

  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  //   const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'link'];
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  ///editor end

  const [showImgTagModal, setShowImgAltTagModal] = useState(false);
  const handleToggleImgAltModal = () => {
    setShowImgAltTagModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    // Save the image alt tag value to state
    setFieldValue("imageAltTag", value);
    setShowImgAltTagModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };
  const handleCloseImgTagModal = () => {
    setShowImgAltTagModal(false);
  };

  if (!getsinglePressRelease.data) {
    return <div>Loading....</div>;
  }
  const preSelectedResortIds = values.resortId;
  // const preSelectedResortIds = preSelectedResortIdsString ? preSelectedResortIdsString.split(',').map(Number) : [];

  const handleChangeResort = (selected) => {
    setSelectedOptions(selected);
    const resortIdValue =
      selected.length > 0 ? selected.map((option) => option.value) : null;

    // Update state or form values
    setValues({
      ...values,
      resortId: resortIdValue,
    });
  };

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to="/manage-press-releases"
                  >
                    manage-press-releases
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Press Release
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-press-releases"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Press Release</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-xl-12 col-lg-7">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Press Release</h5>
                  <div className="right-actions d-none">
                    <a
                      href="#"
                      className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                    >
                      Save Draft
                    </a>
                    <a
                      href="#"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                    >
                      Preview
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form action="#" method="get">
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Title <span className="mandatory">*</span>
                          </label>

                          <input
                            ref={titleRef}
                            name="title"
                            value={values.title}
                            onChange={handleTitleChange}
                            onBlur={handleBlur}
                            maxLength={100}
                            id="Name"
                            className="form-control py-3 shadow-none px-4"
                          />
                          {errors.title && touched.title ? (
                            <p className="form-error text-danger">
                              {errors.title}
                            </p>
                          ) : error.title ? (
                            <p className="form-error text-danger">
                              {error.title}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <div className="mb-4">
                            <div className="d-flex align-items-center">
                              <label className="form-label mb-0">
                                {" "}
                                Image <span className="mandatory">*</span>
                              </label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleImgAltModal("IMAGEALTTAG")
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!maincroppedImage && !image && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={showCropMediaModal}
                                ref={imageRef}
                                tabIndex={-1}
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Upload image from gallery
                                  </button>
                                </div>
                                {/* <input
                                  className="position-absolute top-0"
                                  type="file"
                                  name="offerfile"
                                  onChange={handleofferfilechange}
                                  id="UploadCover"
                                />
                                <span className="upload-icon m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <i className="fa fa-image"></i>
                                </span>
                                <div className="upload-file text-center">
                                  <div className="drag-drop w-100 mt-3">
                                    <p className="m-0">Press Release image</p>
                                  </div>
                                </div> */}
                              </label>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={handleRemoveImage}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {err ? (
                              <p className="form-error text-danger">
                                {/* Please select a file{" "} */}
                              </p>
                            ) : (
                              ""
                            )}
                            {/* {!maincroppedImage && image && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  width: "200px",
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <div>
                                  <a href={image} target="_blank">
                                    <img
                                      src={image}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={handleRemoveImage}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {errors.offerfile && touched.offerfile ? (
                              <p className="form-error text-danger">
                                {errors.offerfile}
                              </p>
                            ) : error.file ? (
                              <p className="form-error text-danger">
                                {error.file}
                              </p>
                            ) : !errors.offerfile &&
                              error.file &&
                              touched.offerfile ? (
                              <p className="form-error text-danger">
                                Please select a file
                              </p>
                            ) : null} */}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                          <label
                            className="form-label"
                            htmlFor="pressReleaseDate"
                          >
                            Press Release Date{" "}
                            {mandatoryFields.includes("pressReleaseDate") && (
                              <span className="mandatory">*</span>
                            )}
                          </label>
                          <input
                            ref={pressReleaseDateRef}
                            onChange={handleChange}
                            value={values.pressReleaseDate}
                            onBlur={handleBlur}
                            selected={values.pressReleaseDate}
                            type="date"
                            name="pressReleaseDate"
                            id="pressReleaseDate"
                            className="form-control py-3 shadow-none px-4"
                          />
                          {errors.pressReleaseDate &&
                          touched.pressReleaseDate ? (
                            <p className="form-error text-danger">
                              {errors.pressReleaseDate}
                            </p>
                          ) : error.pressReleaseDate ? (
                            <p className="form-error text-danger">
                              {error.pressReleaseDate}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-4 mb-4">
                          <label
                            className="form-label"
                            htmlFor="pressReleaseTime"
                          >
                            Press Release Time{" "}
                            {mandatoryFields.includes("pressReleaseTime") && (
                              <span className="mandatory">*</span>
                            )}
                          </label>
                          <input
                            ref={pressReleaseTimeRef}
                            onChange={handleChange}
                            value={values.pressReleaseTime}
                            onBlur={handleBlur}
                            type="time"
                            name="pressReleaseTime"
                            selected={values.pressReleaseTime}
                            id="pressReleaseTime"
                            className="form-control py-3 shadow-none px-4"
                          />
                          {errors.pressReleaseTime &&
                          touched.pressReleaseTime ? (
                            <p className="form-error text-danger">
                              {errors.pressReleaseTime}
                            </p>
                          ) : error.pressReleaseTime ? (
                            <p className="form-error text-danger">
                              {error.pressReleaseTime}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="OfferType">
                              Publish on resort website
                            </label>
                            {/* <select
                              name="resortId"
                              id="OfferType"
                              className="form-control form-select py-3 shadow-none px-4"
                              onChange={handleChange}
                              value={values.resortId}
                            >
                              <option value="">---Select---</option>
                              {getAllResorts &&
                                getAllResorts.data &&
                                getAllResorts.data.data &&
                                getAllResorts.data.data.map((option) => (
                                  <option
                                    key={option.brand_resort_id}
                                    value={option.brand_resort_id}
                                  >
                                    {option.resort_name}
                                  </option>
                                ))}
                            </select> */}
                            <Select
                              name="resortId"
                              id="OfferType"
                              className="3col active  py-0 shadow-none px-6"
                              onChange={handleChangeResort}
                              // value={values.resortId}
                              // value={selectedOptions}
                              value={(getAllResorts?.data?.data || [])
                                .map((option) => ({
                                  value: option.brand_resort_id,
                                  label: option.resort_name,
                                }))
                                .filter(
                                  (option) =>
                                    preSelectedResortIds &&
                                    preSelectedResortIds.includes(option.value)
                                )}
                              onBlur={handleBlur}
                              isMulti
                              options={(getAllResorts?.data?.data || []).map(
                                (option) => ({
                                  value: option.brand_resort_id,
                                  label: option.resort_name,
                                })
                              )}
                              noOptionsMessage={() => null}
                            ></Select>
                          </div>
                          {errors.resortId && touched.resortId ? (
                            <p className="form-error text-danger">
                              {errors.resortId}
                            </p>
                          ) : error.resortId ? (
                            <p className="form-error text-danger">
                              {error.resortId}
                            </p>
                          ) : !errors.resortId &&
                            error.resortId &&
                            touched.resortId ? (
                            <p className="form-error text-danger">
                              Please select a resort
                            </p>
                          ) : null}
                        </div>
                        <div className="col-12 mb-4">
                          <label className="form-label" htmlFor="Description">
                            Description <span className="mandatory">*</span>
                          </label>
                          {/* <input type="time" name="" id="full-editor" className="form-control py-3 shadow-none px-4" />
                                                    <input type="time" name="" id="basic-editor" className="form-control py-3 shadow-none px-4" /> */}
                          <ReactQuill
                            ref={(node) => {
                              fullEditorRef.current = node;
                              descriptionRef.current = node;
                            }}
                            theme="snow"
                            modules={{
                              toolbar: true,
                            }}
                            name="description"
                            style={{ height: "300px" }}
                            value={values.description}
                            // onBlur={handleBlur}
                            //  onChange={handleChange}
                            // onBlur={handleBlur} type="text" name="description" value={values.description}
                          />
                          {errors.description && touched.description ? (
                            <p className="form-error text-danger">
                              {errors.description}
                            </p>
                          ) : error.description ? (
                            <p className="form-error text-danger">
                              {error.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Tags
                          </label>
                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={100}
                              type="text"
                              name="tags"
                              value={values.tags}
                              id="tags"
                              className="form-control py-3 shadow-none px-4"
                              placeholder="Enter tags"
                              data-role="tagsinput"
                            />
                          </div>
                          {/* {errors.tags && touched.tags ? (
                            <p className="form-error text-danger">
                              {errors.tags}
                            </p>
                          ) : error.tags ? (
                            <p className="form-error text-danger">
                              {error.tags}
                            </p>
                          ) : null} */}
                        </div>
                        {/* <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Slug
                          </label>
                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={100}
                              type="text"
                              name="slug"
                              value={values.slug}
                              id="slug"
                              className="form-control py-3 shadow-none px-4"
                              placeholder="Enter slug"
                              data-role="tagsinput"
                            />
                          </div>  */}

                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Slug
                            <span className="mandatory">*</span>
                          </label>

                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              ref={slugRef}
                              onBlur={handleBlur}
                              type="text"
                              maxLength={100}
                              name="slugName"
                              value={values?.slugName?.trim()}
                              id="slugName"
                              className="form-control py-3 shadow-none px-4"
                              placeholder="Enter slug"
                              data-role="tagsinput"
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                          {errors.slugName && touched.slugName ? (
                            <p className="form-error text-danger">
                              {errors.slugName}
                            </p>
                          ) : error.slugName ? (
                            <p className="form-error text-danger">
                              {error.slugName}
                            </p>
                          ) : null}
                        </div>

                        {/* meta and og code  */}
                        <div className="col-12">
                          <ul
                            className="nav nav-pills atmos-tabs d-flex flex-wrap pt-3 border-bottom pb-3"
                            id="myTab"
                            role="tablist"
                          >
                            {/* meta tags  */}
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link text-uppercase active"
                                id="highlight-extra-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#meta-tags"
                                type="button"
                                role="tab"
                                aria-controls="highlight-extra-tab-pane"
                                aria-selected="false"
                              >
                                Meta Tags
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link text-uppercase "
                                id="highlight-extra-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#Open-Graph"
                                type="button"
                                role="tab"
                                aria-controls="highlight-extra-tab-pane"
                                aria-selected="false"
                              >
                                OG Tags
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="tab-content mt-4 mb-0"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade "
                            id="Open-Graph"
                            role="tabpanel"
                            aria-labelledby="Open-Graph"
                            tabIndex="0"
                          >
                            <OGtags
                              handelChange={handleChange}
                              values={values}
                              ogImage={ogImagePlaceholer}
                              showCropMediaModal={setCropMediaModal}
                              setSelectedImageValue={setSelectedImageValue}
                              setOgimage={setOgImage}
                            />
                          </div>
                          <div
                            className="tab-pane fade show active"
                            id="meta-tags"
                            role="tabpanel"
                            aria-labelledby="Amenities-tab"
                            tabIndex="0"
                          >
                            <div className="col-md-12 mb-4">
                              <label className="form-label">Keywords </label>
                              <input
                                name="keyWords"
                                value={values.keyWords}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                id="Keywords"
                                className="form-control py-3 shadow-none px-4"
                              />
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  // htmlFor="OfferName"
                                >
                                  Meta Title{" "}
                                </label>
                                <input
                                  name="metaTitle"
                                  value={values.metaTitle}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  id="metaTitle"
                                  className="form-control py-3 shadow-none px-4"
                                  maxLength={70}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  // htmlFor="OfferName"
                                >
                                  Meta Description{" "}
                                </label>
                                <input
                                  name="metaDescription"
                                  value={values.metaDescription}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  id="metaDescription"
                                  className="form-control py-3 shadow-none px-4"
                                  maxLength={170}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bottom-actions mt-4 text-end">
                          <Link
                            to={`/manage-press-releases`}
                            value="Cancel"
                            className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                          >
                            Cancel
                          </Link>

                          {bwebsiteId === "TOC" && (
                            <Button
                              // href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setAction("PREVIEW");
                                // handleSubmit();
                                handelFocus();
                              }}
                              className="btn btn-primary btn-theme  fw-semibold text-uppercase px-3 py-2 me-2"
                              disabled={isPreviewLoading}
                            >
                              {isPreviewLoading ? (
                                <div
                                  class="spinner-border text-primary"
                                  role="status"
                                ></div>
                              ) : (
                                "PREVIEW"
                              )}
                            </Button>
                          )}
                          {/* <input type="submit" onclick={handleSubmit} value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" /> */}
                          {isLoading ? (
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setAction("SAVE");
                                handelFocus();
                                // handleSubmit();
                              }}
                              type="submit"
                              disabled={isLoading}
                              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            >
                              Update
                            </Button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-5 mt-lg-0">
                            <div className="card">
                                <div className="card-header py-3 bg-transparent border-bottom">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 fw-medium pe-3">Publish</h5>
                                        
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="press-top-action d-flex justify-content-between">
                                        
                                    </div>
                                    <div className="press-body">
                                       
                                        <ul className="list-unstyled">
                                            <li>
                                                <span>Publish Date</span>
                                                <div className="press-actions d-flex">
                                                    
                                                    <span className="press-date-wrap d-flex">
                                                        <input
                                                            name="publish_date"
                                                            type="date"
                                                            className="press-date-input border-0 p-0 shadow-none outline-0"
                                                            onBlur={handleBlur}
                                                            
                                                            value={values.publish_date}
                                                            onChange={handleChange}
                                                            defaultValue={
                                                                getsinglePressRelease?.data?.data
                                                                    ?.published_date !== null
                                                                    ? getsinglePressRelease.data.data.published_date.split(
                                                                        "T"
                                                                    )[0]
                                                                    : ""
                                                            }
                                                        />
                                                        <div className="edit-action position-absolute end-0 top-0 d-none z-2 bg-white">
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </div>
                                                        
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <span>Publish Time</span>
                                                <div className="press-actions d-flex">
                                                   
                                                    <span>
                                                        <input
                                                            className="border-0"
                                                            type="time"
                                                            onBlur={handleBlur}
                                                            name="publish_time"
                                                            value={values.publish_time}
                                                           
                                                            onChange={handleChange}
                                                      
                                                        />
                                                       
                                                    </span>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        handleClose={handleCloseModal}
        rowData={selectedRowData}
        rowNameData={selectedRowNameData}
      />

      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {/* <div className="modal fade" id="EditimageModal" tabindex="-1" aria-labelledby="EditimageModalLabel" aria-hidden="true">
                        <div className="modal-dialog mw-100">
                            <div className="modal-content"> */}

          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedImagePress={setmainCroppedImage}
              setmainCroppedFilePress={setmainCroppedFile}
              setting_value={
                selectedImageValue === "OGIMAGE"
                  ? getOgAspectRatio &&
                    getOgAspectRatio.data &&
                    getOgAspectRatio.data.data &&
                    getOgAspectRatio.data.data.setting_value
                  : getAspectRatio &&
                    getAspectRatio.data &&
                    getAspectRatio.data.data &&
                    getAspectRatio.data.data.setting_value
              }
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              offerValForBanner={selectedImageValue}
              setSelectedImageValue={setSelectedImageValue}
              setmainCroppedFile={setOgImage}
            />
          )}
          {/* </div>
                        </div>
                    </div> */}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showImgTagModal}
        handleCloseImageTagModal={handleCloseImgTagModal}
        initialImageAltTag={values.imageAltTag}
        tagType="IMAGEALTTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="imageAltTag"
      />
    </>
  );
};

export default CreatePressRelease;
